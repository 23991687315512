// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-uh-pages-404-js": () => import("./../../../src/UH/pages/404.js" /* webpackChunkName: "component---src-uh-pages-404-js" */),
  "component---src-uh-pages-about-us-js": () => import("./../../../src/UH/pages/about-us.js" /* webpackChunkName: "component---src-uh-pages-about-us-js" */),
  "component---src-uh-pages-blog-js": () => import("./../../../src/UH/pages/blog.js" /* webpackChunkName: "component---src-uh-pages-blog-js" */),
  "component---src-uh-pages-contact-js": () => import("./../../../src/UH/pages/contact.js" /* webpackChunkName: "component---src-uh-pages-contact-js" */),
  "component---src-uh-pages-index-js": () => import("./../../../src/UH/pages/index.js" /* webpackChunkName: "component---src-uh-pages-index-js" */),
  "component---src-uh-pages-ppc-js": () => import("./../../../src/UH/pages/ppc.js" /* webpackChunkName: "component---src-uh-pages-ppc-js" */),
  "component---src-uh-pages-privacy-policy-js": () => import("./../../../src/UH/pages/privacy-policy.js" /* webpackChunkName: "component---src-uh-pages-privacy-policy-js" */),
  "component---src-uh-pages-redirect-js": () => import("./../../../src/UH/pages/redirect.js" /* webpackChunkName: "component---src-uh-pages-redirect-js" */),
  "component---src-uh-pages-terms-of-service-js": () => import("./../../../src/UH/pages/terms-of-service.js" /* webpackChunkName: "component---src-uh-pages-terms-of-service-js" */),
  "component---src-uh-templates-blog-js": () => import("./../../../src/UH/templates/Blog.js" /* webpackChunkName: "component---src-uh-templates-blog-js" */),
  "component---src-uh-templates-region-js": () => import("./../../../src/UH/templates/Region.js" /* webpackChunkName: "component---src-uh-templates-region-js" */),
  "component---src-uh-templates-theme-js": () => import("./../../../src/UH/templates/Theme.js" /* webpackChunkName: "component---src-uh-templates-theme-js" */),
  "component---src-uh-templates-themed-region-js": () => import("./../../../src/UH/templates/ThemedRegion.js" /* webpackChunkName: "component---src-uh-templates-themed-region-js" */)
}

